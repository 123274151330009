<template>
  <client-only>
    <Teleport to="#global-teleport-target">
      <div class="relative z-[60] w-full">
        <transition
          enter-from-class="opacity-0 scale-75"
          enter-active-class="duration-200"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-200"
          leave-to-class="opacity-0 scale-75"
        >
          <div
            v-if="!isMobile && previewImgSrc"
            class="preview-wrapper fixed left-1/2 top-1/2 z-[45] flex -translate-x-1/2 -translate-y-1/2 cursor-default flex-col items-center justify-center shadow-around"
            :class="{ centered: centered }"
            @mouseleave="hidePreview"
            @mouseenter="showPreview(previewImgSrc)"
            @click.stop
          >
            <div class="preview-inner flex bg-white">
              <div class="flex flex-col">
                <CommonLink
                  underline
                  :href="barometerUrl"
                  class="mx-auto pt-2 text-center"
                  @click="emits('moveToBarometer')"
                >
                  {{ car.name }}
                </CommonLink>
                <CommonLink
                  :href="barometerUrl"
                  class="preview-img relative flex h-80 flex-1 flex-col items-center justify-center"
                  @click="emits('moveToBarometer')"
                >
                  <ElementsCarThumbnailPlaceholder
                    v-if="previewImgSrc === EMPTY_IMG_PLACEHOLDER"
                    class="border-0"
                  />

                  <img
                    v-else
                    class="max-h-[calc(100%-30px)] max-w-full"
                    :src="previewImgSrc"
                    alt=""
                  />
                </CommonLink>
              </div>

              <div
                class="preview-description ml-2 w-52 overflow-auto border-l p-2"
              >
                <div class="flex flex-wrap">
                  <div
                    v-for="img in images.length > MAX_THUMBNAIL_IMAGES
                      ? images.slice(0, MAX_THUMBNAIL_IMAGES - 1)
                      : images"
                    :key="img.links.xs"
                    class="flex h-[40px] w-1/3 items-center justify-center"
                    @mouseenter="showPreview(img.links.l, true)"
                  >
                    <img
                      class="h-full w-full border-2 object-cover object-center"
                      :class="{
                        'border-primary': previewImgSrc === img.links.l
                      }"
                      :src="img.links.xs"
                      :alt="img.description.categoryLabel"
                    />
                  </div>

                  <CommonTooltip
                    :tooltip="$t('cars-list.gallery.more')"
                    class="w-1/3"
                  >
                    <common-link
                      :href="detailsUrl"
                      target="_self"
                      :aria-label="$t('cars-list.gallery.more')"
                      class="relative block h-[40px] w-full after:absolute after:inset-0 after:z-10 after:bg-primary after:opacity-75"
                      @click="emits('moveToDetails')"
                    >
                      <span class="relative z-20 block h-full w-full text-lg">
                        <span
                          class="flex h-full w-full items-center justify-center"
                        >
                          <IconCSS name="bi:images" class="text-white" />
                        </span>
                      </span>
                    </common-link>
                  </CommonTooltip>
                </div>

                <div class="py-2 text-xs">
                  <DynamicAuctionItemListCarParameter
                    v-if="firstRegistration"
                    :title="firstRegistration.name"
                    :value="firstRegistration.value"
                    inline
                  >
                    <i class="ab-icon ab-icon-date h-6"></i>
                  </DynamicAuctionItemListCarParameter>

                  <ElementsCarMileageParameters :car="car" inline />

                  <DynamicAuctionItemListCarParameter
                    v-if="power"
                    :title="powerTitle"
                    :value="power"
                    inline
                  >
                    <div class="flex justify-center">
                      <i class="ab-icon ab-icon-performance h-5"></i>
                    </div>
                  </DynamicAuctionItemListCarParameter>

                  <DynamicAuctionItemListCarParameter
                    v-if="amountOfOwners"
                    :title="amountOfOwners.name"
                    :value="amountOfOwners.value"
                    inline
                  >
                    <div class="flex justify-center">
                      <i class="ab-icon ab-icon-owner h-5"></i>
                    </div>
                  </DynamicAuctionItemListCarParameter>

                  <p class="my-2.5">{{ desc }}</p>

                  <p>{{ Object.values(car?.ranking ?? {}).join(', ') }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </Teleport>
  </client-only>
</template>

<script lang="ts" setup>
import { register } from 'swiper/element/bundle'
import { computed, ref } from 'vue'
import { useIsMobile } from '@autobid/ui/composables/useIsMobile'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { useCarDescription } from '@autobid/ui/composables/car/useCarDescription'
import { getFirstRegistration } from '@autobid/ui/utils/car/getFirstRegistration'
import { useCarPower } from '@autobid/ui/composables/car/useCarPower'
import { getAmountOfOwners } from '@autobid/ui/utils/car/getAmountOfOwners'
import type { AuctionCar, CarImage } from '@autobid/ui/types/Car'

interface Props {
  images: CarImage[]
  car?: AuctionCar
  centered?: boolean
}

const emits = defineEmits<{
  (e: 'moveToBarometer'): void
  (e: 'moveToDetails'): void
}>()

const props = defineProps<Props>()

const MAX_THUMBNAIL_IMAGES = 5

const { powerTitle, getPower } = useCarPower()
const { getDescription } = useCarDescription()
const desc = computed(() => {
  return props.car ? getDescription(props.car) : ''
})
const firstRegistration = computed(() =>
  props.car ? getFirstRegistration(props.car.equipments) : ''
)
const amountOfOwners = computed(() =>
  props.car ? getAmountOfOwners(props.car.equipments) : ''
)
const power = computed(() => (props.car ? getPower(props.car.equipments) : ''))
const isMobile = useIsMobile()
const previewImgSrc = ref('')
const debounce = ref<NodeJS.Timeout | null>(null)
const PREVIEW_DELAY = 300
const appId = computed(() => props.car?.appId)
const { getLink } = useAuctionPagesLink(appId)
const detailsUrl = computed(() =>
  getLink({
    page: 'details',
    slug: props.car.slug
  })
)
const barometerUrl = computed(() =>
  getLink({
    page: 'barometer',
    slug: props.car.slug
  })
)
const EMPTY_IMG_PLACEHOLDER = 'empty'

const showPreview = (_imgSrc?: string, skipDelay?: boolean) => {
  const imgSrc = _imgSrc ?? EMPTY_IMG_PLACEHOLDER

  if (isMobile.value) return

  if (debounce.value) {
    clearTimeout(debounce.value)
  }

  debounce.value = setTimeout(
    () => {
      previewImgSrc.value = imgSrc
    },
    skipDelay ? 0 : PREVIEW_DELAY
  )
}

const hidePreview = () => {
  if (isMobile.value) return

  if (debounce.value) {
    clearTimeout(debounce.value)
  }

  debounce.value = setTimeout(() => {
    previewImgSrc.value = ''
  }, PREVIEW_DELAY)
}

defineExpose({
  hidePreview,
  showPreview,
  previewImgSrc
})

register()
</script>

<style lang="scss" scoped>
.preview {
  @media (max-width: 1149px) {
    &-wrapper {
      display: none !important;
    }
  }

  @media (min-width: 1150px) {
    &-description {
      width: 200px;
      min-width: 200px;
    }

    &-img {
      width: 600px;
    }

    &-inner {
      height: 370px;
    }

    &-wrapper {
      &:not(.centered) {
        margin-left: calc((100vw - 800px) / 2 - 30px);
      }
    }
  }

  @media (min-width: 1440px) {
    &-wrapper {
      &:not(.centered) {
        margin-left: 300px;
      }
    }
  }
}
</style>
